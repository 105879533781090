import VisitTypes, { DisplayVisitType, OutsideTheUSVisitTypes } from '../../../util/visit-types'
import VisitReasonListField from '../../../components/visit-creation/VisitReasonListField'
import withForm from '../../../hoc/withForm'
import MedicalVisitReasons, {
  HealthCoachVisitReasons,
  SuccessCoachVisitReasons,
  TherapyVisitReasons,
} from '../../../util/visit-reasons'
import InfoCard from '../../../components/common/InfoCard'
import VisitReasonIllustrationUrl from '../../../assets/images/visit_reason_illustration.svg'
import { VisitTypes as VisitType } from '../../../types/visit/visitTypes'
import PendingVisit from '../../../types/visit/pendingVisit'
import { AnyObjectSchema } from 'yup'
import { useEffect, useMemo, useRef } from 'react'
import { isSuccess } from 'util/helpers'
import { isLocationOutsideUS, isUSLocation } from 'util/visit-location'
import VisitLimitDialog from '../VisitLimitDialog'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import VisitTypeNotAllowedDialog from '../VisitTypeNotAllowedDialog'

type Mutable<T> = { -readonly [K in keyof T]: T[K] }

const getPossibleReasons = (visitType: VisitType) => {
  switch (visitType) {
    case VisitTypes.medical:
      return MedicalVisitReasons
    case VisitTypes.therapy:
    case VisitTypes.psychiatry: {
      return TherapyVisitReasons
    }
    case VisitTypes.healthCoach:
      return HealthCoachVisitReasons
    case VisitTypes.success:
      return SuccessCoachVisitReasons
    default:
      return []
  }
}

const getEmergencyInfoDescription = (locationCountry) =>
  isUSLocation(locationCountry)
    ? 'If you’re experiencing an emergency, please call 911, campus safety, or go to the nearest emergency room.'
    : 'If you’re experiencing an emergency, and you’re outside of the U.S., please contact local emergency resources and your school’s study abroad personnel. TimelyCare providers are not equipped to address emergency situations that occur internationally.'

type VisitReasonFormProps = {
  initialValues: object
  schema: AnyObjectSchema
  pendingVisit: PendingVisit
  setFieldValue: () => void
  handleSubmit: () => void
  values: {
    reasonForVisit: string
  }
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: ({ disabled }: { disabled: boolean }) => JSX.Element
  handleSlrLocationHardStop: () => void
}

const VisitReasonForm = (props: VisitReasonFormProps) => {
  const { values, dirty, isValid, handleSubmit, pendingVisit, handleSlrLocationHardStop } = props

  const successVisit = useMemo(() => {
    return isSuccess(pendingVisit.visitType)
  }, [pendingVisit.visitType])

  const typeNotAllowedModalRef = useRef<ModalRef>(null)

  const isOutsideTheUS = useMemo(() => {
    return isLocationOutsideUS(pendingVisit?.locationCountry)
  }, [pendingVisit.locationCountry])

  const renderComponent = () => {
    if (!pendingVisit) return null
    const reasons = getPossibleReasons(pendingVisit.visitType)
    return (
      <VisitReasonListField
        reasons={reasons as Mutable<typeof reasons>}
        name={'reasonForVisit'}
        value={values.reasonForVisit}
        reasonTitle={successVisit ? 'What would you like to discuss?' : 'What would you like to be seen for?'}
      />
    )
  }

  const { locationCountry } = pendingVisit
  const emergencyInfoDescription = getEmergencyInfoDescription(locationCountry)

  const isTherapyNow = pendingVisit.visitType === 'therapy' && pendingVisit.scheduledType === 'now'

  useEffect(() => {
    if (isOutsideTheUS && !(OutsideTheUSVisitTypes as VisitType[]).includes(pendingVisit.visitType) && !isTherapyNow) {
      typeNotAllowedModalRef.current?.open()
    }
  }, [isOutsideTheUS, pendingVisit.visitType, pendingVisit.scheduledType, isTherapyNow])

  return (
    <form onSubmit={handleSubmit} className="flex w-full flex-1 flex-col">
      <div className="grid max-w-[1130px] sm:grid-cols-[500px] 2xl:grid-cols-[500px_1fr]">
        <div>
          {!successVisit && (
            <InfoCard className="mb-8" title="Contact the right people" description={emergencyInfoDescription} />
          )}
          {renderComponent()}
        </div>
        <div className="hidden items-center justify-center 2xl:flex ">
          <img src={VisitReasonIllustrationUrl} className="h-auto w-[465px] max-w-full" alt="" />
        </div>
      </div>
      {props.children({ disabled: !values.reasonForVisit ? !dirty || !isValid : !isValid })}
      <VisitTypeNotAllowedDialog
        ref={typeNotAllowedModalRef}
        serviceType={DisplayVisitType[pendingVisit.visitType]}
        handleSlrLocationHardStop={handleSlrLocationHardStop}
      />
    </form>
  )
}

export default withForm(VisitReasonForm)
