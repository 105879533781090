import moment from 'moment'
import jwtDecode from 'jwt-decode'
import {
  REFRESH_TOKEN,
  TOKEN,
  TOKEN_CREATED_AT,
  TOKEN_EXPIRES_AT,
  USER_ID,
  PASSWORD_EXPIRED,
} from './session-constants'
import { sessionPersistence } from './storage'

export const insertAuthValuesIntoSessionStorage = ({
  auth,
  passwordExpired,
}: {
  auth: { refreshToken: string; userId: string; token: string }
  passwordExpired?: boolean
}) => {
  if (!auth) return
  const { refreshToken, userId, token } = auth
  const decoded: { iat: number; exp: number } = jwtDecode(token)
  const createdAt = moment(decoded.iat * 1000).toISOString()
  const expiresAt = moment(decoded.exp * 1000).toISOString()
  sessionPersistence.set(USER_ID, userId)
  sessionPersistence.set(TOKEN, token)
  sessionPersistence.set(TOKEN_CREATED_AT, createdAt)
  sessionPersistence.set(TOKEN_EXPIRES_AT, expiresAt)
  sessionPersistence.set(REFRESH_TOKEN, refreshToken)
  sessionPersistence.set(PASSWORD_EXPIRED, passwordExpired ? 'true' : 'false')
}
