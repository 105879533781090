export const APP_NAME = 'TimelyCare'
export const MAIN_SITE_BASE_URL_SHORT = 'timelycare.com'
export const MAIN_SITE_BASE_URL = `https://${MAIN_SITE_BASE_URL_SHORT}`
export const APP_SITE_BASE_URL = `https://app.${MAIN_SITE_BASE_URL_SHORT}`

export const TERMS_CONDITIONS_LINK = `${MAIN_SITE_BASE_URL}/terms-conditions/`
export const TERMS_CONDITIONS_COMMUNITY_LINK = `${MAIN_SITE_BASE_URL}/terms-conditions-community-support/`

export const PRIVACY_POLICY_LINK = `${MAIN_SITE_BASE_URL}/privacy-policy/`
export const COOKIE_POLICY_LINK = `${MAIN_SITE_BASE_URL}/cookie-policy/`

export const RECOMMENDED_PROVIDER_COUNT = 3

export const SCROLL_INTO_VIEW_PARAM: { [key: string]: string | number } = {
  behavior: 'smooth',
  block: 'start',
  inline: 'nearest',
  duration: 400,
}

export const TIMEOUT: { timeout: number } = { timeout: 7000 }
