import { Provider } from 'react-redux'
import { BrowserRouter, useHistory } from 'react-router-dom'
import configure from '@ui-components-3/fast-forms/lib/utils/configure'
import { ToastContainer } from 'util/toast'
import FlagProvider from 'components/feature-flag/FlagProvider'
import { store } from 'redux/store'
import * as VisitThunks from 'redux/thunks/visits'
import Root from 'routes/Root'
import Interceptors from 'util/interceptors'
import type { Store } from 'redux'
import { PostHogProvider } from 'posthog-js/react'
import Config from 'config'
import UserAnalytics from 'components/analytics/UserAnalytics'

/*
  TODO: ts-ignore and any can be removed when /redux/thunks/visits is typed
*/
// TODO: move this config to a single file
configure({
  fetchForms: (formGroups, extraParams) =>
    store.dispatch(VisitThunks.getForms({ formGroup: formGroups[0] }, extraParams?.token)),
  fetchFormResponses: async (formGroups, targetIds, extraParams) => {
    switch (extraParams?.type) {
      case 'visit_assessments': {
        if (targetIds) {
          const responses = []

          const { pendingVisitId } = targetIds
          const memberId = store.getState().member.meta.loggedInMemberId
          const { data }: { data: any } = await store.dispatch(VisitThunks.fetchPendingVisit(pendingVisitId, memberId))
          if (data?.intakes?.gad7) {
            responses.push(data?.intakes?.gad7)
          }
          if (data?.intakes?.phq9) {
            responses.push(data?.intakes?.phq9)
          }
          return responses
        }
        break
      }
      case 'visit_survey':
      case 'fs_k12_visit_survey':
      case 'success_coaching_survey': {
        // TODO - add fetching for visit_survey form responses
        return []
      }
    }
    throw new Error('Wrong responses')
  },
  // @ts-ignore
  submitForm: (formBase, targetIds, extraParams) => {
    switch (extraParams?.type) {
      case 'visit_assessments': {
        if (targetIds) {
          const { pendingVisitId } = targetIds
          // @ts-ignore
          return store.dispatch(VisitThunks.updateVisitAssesmentResponse(pendingVisitId, formBase.form))
        } else {
          throw new Error('pendingVisitId missing from targetIds')
        }
      }
      case 'visit_survey':
      case 'fs_k12_visit_survey':
      case 'success_coaching_survey': {
        if (targetIds) {
          return store.dispatch(
            VisitThunks.saveVisitSurveyAnswers(formBase, targetIds.pendingVisitId, extraParams?.token),
          )
        }
      }
    }

    throw new Error('unknown form type')
  },
})

let interceptorsSet = false

/**
 * TODO: history is needed here just for logout.
 * Normally this should be managed on router level (somewhere in AuthRoutes).
 * After history dependency is removed component may also be removed
 **/
export const TemporaryInterceptorManager = ({ store }: { store: Store }) => {
  const history = useHistory()

  if (!interceptorsSet) {
    interceptorsSet = true
    Interceptors.setupInterceptors(store, history)
  }

  return null
}

const options = {
  api_host: Config.posthogHost,
  capture_pageview: false,
  capture_pageleave: true, // Opt back in because disabling $pageview capture disables $pageleave events
}

const App = () => {
  return (
    <FlagProvider>
      <PostHogProvider apiKey={Config.posthogKey} options={options}>
        <Provider store={store}>
          <BrowserRouter basename="/">
            <TemporaryInterceptorManager store={store} />
            <UserAnalytics />
            <Root />
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable={false}
              pauseOnHover
              role="alert"
            />
          </BrowserRouter>
        </Provider>
      </PostHogProvider>
    </FlagProvider>
  )
}

export default App
