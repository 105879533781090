export const INSTRUCTIONS = ['Please read Section A, Introduction.', 'Complete Section B.']

export const EFFECTIVE_VISIT_NOTES = [
  'You are required by law to select the Country and State in which you are physically located at the time of your visit(s). Failure to accurately identify your location will result in cancellation of your visit(s).',
  'Arrive for your visit on-time.',
  'Use a reliable device (computer, tablet, or smartphone) with up-to-date software and a stable, password-protected internet connection.',
  'Ensure you have a quiet, private space with adequate lighting for clear video communication.',
  'You are advised to connect 5-10 minutes prior to your appointment time to confirm your internet speed is sufficient to conduct the visit and your webcam and microphone work as expected. This time may also be used to complete assigned screening tools prior to visits.',
  'Have any necessary documents, medical records, and identification ready.',
  'Be ready to discuss your medical history, medications, current symptoms, and any questions you may have. Engage actively with your healthcare provider by providing detailed and honest responses.',
  'Take note of any post-visit instructions, medications, or follow-up appointments.',
  'Contact our Customer Support team at (833) 484-6359 if you encounter any issues or have further questions after the visit.',
  'If you need to upload a photograph to your visit, do not upload images of breasts, genitalia, buttocks or other sensitive body areas. Uploaded photographs will become a permanent part of your medical record.',
]

export const PREPARED_INFORMATION = [
  'Identifiers such as your legal name and date of birth',
  'Emergency contact names, relationship, and contact phone numbers',
  'Primary Care Physician, therapists, psychiatrist, or other healthcare Providers name(s) and phone number(s)',
  'Valid picture ID for identification purposes',
  'Confirmation of your location at the time of the appointment',
]

export const RISKS = [
  'Potential risk of disruptions due to technology or internet problems, which can affect the quality of care.',
  'Potential for misunderstandings due to lack of non-verbal cues and body language in audio-only visits.',
  'Potential risk of data breaches, unauthorized access to personal health information, confidentiality including public discovery, possibility of hackers, household noise or interruptions and other potential risks outside of our control.',
  'Some medical issues may require a physical examination or physical testing that cannot be conducted via telehealth which could lead to inaccurate diagnoses or delayed treatment.',
  'Variations in telehealth regulations across states may affect the delivery of services and may impact medication prescribing capabilities.',
  'Building a therapeutic alliance might be more challenging without face-to-face interaction.',
  'Teletherapy may not be beneficial, and its effectiveness is not guaranteed.',
  'Potential risk for difficulties in monitoring and managing medication side effects without in-person assessments and/or diagnostic tools and tests.',
]

export const BENEFITS = [
  'Enables members and Providers to communicate from any location, ensuring convenient and accessible high-quality care that removes barriers such as transportation, time limitations, traveling expenses, and illness.',
  'Provides access to a nation-wide network of healthcare Providers and specialists.',
  'Minimizes the risk of exposure to contagious diseases.',
  'Facilitates regular follow-ups and medication management without the need for travel.',
  'Supports ongoing treatment and monitoring of psychiatric conditions.',
  'Provides a level of anonymity that may reduce stigma and encourage individuals to seek treatment.',
  'Allows members to receive therapy in a familiar and comfortable environment, which can enhance openness and participation.',
  'Offers more scheduling options, including evenings and weekends, which can accommodate busy lifestyles.',
]

export const PATIENT_RIGHTS = [
  'Privacy and confidentiality of your health information and where it shall be necessary, to seek your consent in order to disclose your information unless permitted by law to disclose without your consent.',
  'Withhold or withdraw your consent to the use of telehealth services without affecting your right to future care or treatment.',
  'Access and request to amend your health records.',
  'Ask questions related to the services provided.',
]

export const TERMINATION_REASONS = [
  'Non-Compliance: Repeated failure to follow treatment plans, including not taking prescribed medications, missing scheduled appointments without prior notice, or not adhering to agreed-upon therapy recommendations.',
  'Abusive Behavior: Any form of abusive, threatening, or inappropriate behavior towards the healthcare provider or administrative staff and/or any violation of the Terms & Conditions.',
  'Fraudulent Activity: Providing false information or engaging in fraudulent activities and/or violation of these Terms and Conditions.',
  'Boundary Violations: Engaging in behaviors that compromise the professional boundaries of the therapeutic relationship.',
  'Safety Concerns: Situations where the provider believes that continuing the relationship would pose a risk to the patient or others.',
]

export const AFTER_HOURS_SUPPORT = [
  'Emergency situations: Dial 911 or visit the nearest emergency room.',
  'Non-emergency inquiries: Contact us at (833) 484-6359. Response times may vary.',
  'Cancellations and rescheduling: Please contact TimelyCare through the “Help Center” located within TimelyCare or call Customer Support at (833) 484-6359 at least 24 hours in advance to avoid a loss of visit credits and cancellation fees.',
  'Complaints can be made through online channels or by calling Customer Support at (833) 484-6359.',
]

export const GENERAL_ACKNOWLEDGEMENT = [
  'I understand that I am expressly authorizing and consenting to services provided by the Providers through the Telehealth Consultations.',
  'I understand the risk, benefits, and limitations of telehealth services whereas there can be no guarantee to the results of all treatments made through this medium.',
  'I understand the services provided by Timely Telehealth, LLC, my rights, and responsibilities related to confidentiality, communication, payment for services, and cancellations.',
  "I understand that confidentiality rights are not absolute. Any matter that may involve abuse, harm, or violence towards a child, an elder, or any person shall permit the provider to report to proper authorities if they believe the possibility of such an event to happen or may have happened. I understand that it is the duty of my provider to notify emergency personnel in the event that my, or other people's safety is at risk.",
  'I understand that the TimelyCare services are not an emergency service. In case of emergency, I shall contact 9-1-1 or any other emergency service provided by the government or any institution.',
]

export const THERAPY_ACKNOWLEDGEMENT = [
  'I acknowledge that therapy outcomes may vary, and there are no guarantees of specific results. However, I am committed to supporting my growth and well-being throughout the therapeutic process. I understand/agree that:',
  'Therapy involves discussing personal and sensitive topics, and I authorize TimelyCare Providers to engage in therapeutic interventions deemed appropriate for my well-being.',
  'Therapy is a collaborative process between the therapist and myself. I agree to participate in sessions as requested by the therapist and to support my therapeutic journey.',
  'Building a therapeutic relationship takes time and commitment. I will attend sessions regularly and communicate openly with my therapist. I understand that I may experience discomfort or emotional distress during therapy sessions.',
]
