import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import ExternalLink from 'components/common/ExternalLink'
import { forwardRef } from 'react'
import { TERMS_CONDITIONS_LINK } from 'util/constants'
import {
  AFTER_HOURS_SUPPORT,
  BENEFITS,
  EFFECTIVE_VISIT_NOTES,
  GENERAL_ACKNOWLEDGEMENT,
  INSTRUCTIONS,
  PATIENT_RIGHTS,
  PREPARED_INFORMATION,
  RISKS,
  TERMINATION_REASONS,
  THERAPY_ACKNOWLEDGEMENT,
} from './formData'

const SelfConsentTermsModal = forwardRef<ModalRef, object>((props, ref) => {
  const Section = ({ title, children }: { title?: string; children: React.ReactNode }) => (
    <div className="flex flex-col gap-2">
      {title && <h5 className="typography-body font-semibold text-neutral-800">{title}</h5>}
      {children}
    </div>
  )

  const List = ({ items, type }: { items: string[]; type?: 'decimal' | 'disc' }) => (
    <ol className={`ml-5 ${type === 'decimal' ? 'list-decimal' : 'list-disc'}`}>
      {items.map((item, index) => (
        <li key={index} className="typography-body text-neutral-800">
          {item}
        </li>
      ))}
    </ol>
  )

  const renderFooter = ({ close }: { close: () => void }) => (
    <button type="button" className="btn btn-neutral" onClick={close}>
      Ok
    </button>
  )

  return (
    <Modal label="Informed Authorization & Consent to Therapy Only" size="l" ref={ref} footer={renderFooter}>
      <div className="flex flex-col gap-6">
        <Section>
          <h5 className="typography-body text-underline text-center font-semibold text-neutral-800">
            IF YOU HAVE OR ARE HAVING A MEDICAL EMERGENCY, IMMEDIATELY CALL YOUR LOCAL EMERGENCY NOTIFICATION SYSTEM
            (911) OR GO TO YOUR LOCAL EMERGENCY ROOM.
          </h5>
          <h5 className="typography-body text-center font-semibold text-neutral-800 underline">Instructions</h5>
          <List items={INSTRUCTIONS} type="decimal" />
        </Section>

        <Section>
          <h5 className="typography-body text-center font-semibold text-neutral-800 underline">
            Section A: Introduction
          </h5>
          <p className="typography-body text-neutral-800">
            Welcome to TimelyCare. Timely Telehealth, LLC, a Texas limited liability company (“TimelyCare”) provides
            access to remote Telehealth Consultations and exchange of information provided by healthcare Providers such
            as doctors, nurse practitioners, psychiatrists, psychologists, therapists, and/or counselors (collectively,
            the “Providers”). The purpose of this form is to collect your consent to participate in teletherapy, through
            electronic mediums such as phone, video, or asynchronous data exchange (the “Telehealth Consultations”),
            inform you of your rights and responsibilities, use of personal and medical information, and the risk and
            benefits of Telehealth Consultations. Please read this document carefully and select the checkbox below to
            indicate your informed consent.
          </p>
        </Section>

        <Section title="What is Teletherapy?">
          <p className="typography-body text-neutral-800">
            Teletherapy is the delivery of therapeutic services such as psychological counseling and support to members,
            allowing clinicians to provide a diagnosis, consultation and treatment using videoconference technology,
            telephone and/or computer. TimelyCare delivers teletherapy via Scheduled Counseling services.
          </p>
        </Section>

        <Section title="In order for you to have an effective TimelyCare visit, please take note of the following:">
          <List items={EFFECTIVE_VISIT_NOTES} type="decimal" />
        </Section>

        <Section title="During your visit please be prepared to provide the following information:">
          <List items={PREPARED_INFORMATION} type="decimal" />
        </Section>

        <Section>
          <p className="typography-body text-neutral-800">
            <span className="typography-body font-semibold text-neutral-800">Confidentiality: </span> TimelyCare is
            committed to maintaining the confidentiality of your personal information. All communications are conducted
            over secure, encrypted channels. However, the confidentiality of communications via electronic devices
            cannot be guaranteed. The laws that protect privacy and confidentiality, as well as the confidentiality of
            medical information through the Health Insurance Portability and Accountability Act (HIPAA) also apply to
            the delivery of telehealth services. Existing laws regarding access to medical records or information are
            also applied to Telehealth Consultations. When using TimelyCare, sharing login credentials is prohibited.
            You are responsible for securing your device and login information. Any breaches or issues arising from your
            device are your responsibility. See{' '}
            <ExternalLink href={TERMS_CONDITIONS_LINK} className="text-primary-500 underline">
              Terms & Conditions
            </ExternalLink>{' '}
            for more information.
          </p>
        </Section>

        <Section title="Access to Provider Appointment & Clinical Documentation">
          <h5 className="typography-body font-semibold text-neutral-800">Risks & Benefits of Telehealth Services</h5>
          <h5 className="typography-body font-semibold text-neutral-800">Risks</h5>
          <List items={RISKS} type="decimal" />
          <h5 className="typography-body font-semibold text-neutral-800">Benefits</h5>
          <List items={BENEFITS} type="decimal" />
        </Section>

        <Section title="Your Rights">
          <p className="typography-body text-neutral-800">
            During your telehealth consultation, you have the right to:
          </p>
          <List items={PATIENT_RIGHTS} type="disc" />
        </Section>

        <Section title="Communication Plan">
          <p className="typography-body text-neutral-800">
            TimelyCare Providers will communicate with you through the TimelyCare application, a secure phone and video
            conferencing platform via the modality you select and as permitted by State regulations for telehealth. If
            your chosen communication modality is not successful either due to low video quality, low internet speed, or
            any other technical difficulties, your provider will call you at the phone number listed in your TimelyCare
            account; please ensure your phone number is accurate, up-to-date, and enabled to allow phone calls from
            TimelyCare.
          </p>
        </Section>

        <Section title="Payment">
          <p className="typography-body text-neutral-800">
            In most cases, the cost for TimelyCare visits is covered by the college, university, institution of higher
            learning, or educational institution partner where you are enrolled or employed. In cases where fees exist
            for visits beyond contracted limits, the fee for telehealth services will be charged prior to your visit.
            TimelyCare does not file with insurance companies and reimbursement for services may vary by carrier; please
            verify with your insurance provider. See{' '}
            <ExternalLink href={TERMS_CONDITIONS_LINK} className="text-primary-500 underline">
              Terms & Conditions
            </ExternalLink>{' '}
            section “Notice of Non-Coverage” for more information.
          </p>
        </Section>

        <Section title="Cancellations">
          <p className="typography-body text-neutral-800">
            You are expected to attend scheduled visits. TimelyCare requires a minimum 24 hours notice for cancellation
            and reschedule. In the event that you either: (i) do not attend a Service; (ii) are more than ten (10)
            minutes late for a Service; or (iii) cancel a Service within twenty-four (24) hours of the scheduled
            Service, you will have deducted one “Clinical Service” credit from your allotted amount, which allotment
            shall be determined in accordance with the contractual obligations between TimelyCare and your enrolled
            college, university or institution of higher learning. See{' '}
            <ExternalLink href={TERMS_CONDITIONS_LINK} className="text-primary-500 underline">
              Terms & Conditions
            </ExternalLink>{' '}
            for more information.
          </p>
        </Section>

        <Section>
          <p className="typography-body text-neutral-800">
            <span className="typography-body font-semibold text-neutral-800">
              Termination of Patient-Provider Relationship:
            </span>{' '}
            Your provider reserves the right to terminate the patient-provider relationship under certain circumstances.
            This decision is not taken lightly and will be communicated clearly and respectfully. In the event of
            termination, your provider will provide appropriate referrals and resources to ensure continuity of care.
            You will receive written notice detailing the reason for termination and the effective date, as well as
            information on how to obtain your medical records. Some examples of reasons for termination include, but are
            not limited to:
          </p>
          <List items={TERMINATION_REASONS} type="decimal" />
        </Section>

        <Section title="After-Hours Support">
          <List items={AFTER_HOURS_SUPPORT} type="disc" />
        </Section>

        <Section>
          <h5 className="typography-body text-center font-semibold text-neutral-800 underline">
            Section B: Consent for Treatment - Therapy
          </h5>
          <p className="typography-body font-neutral  text-neutral-800 underline">
            <span className="typography-body font-bold text-neutral-800">Disclaimer: </span> This consent for treatment
            is only applicable either to: (i) individuals 18 years of age and older; or (ii) individuals under the age
            of 18 who do not, under applicable state law, require an adult to consent to treatment, and individuals
            seeking treatment via a “Clinical Service”. If you are a minor and not legally permitted to provide
            self-consent for a “Clinical Service”, a parent or legal guardian must provide consent on your behalf.
          </p>
        </Section>
        <Section title="General Acknowledgement">
          <List items={GENERAL_ACKNOWLEDGEMENT} type="disc" />
        </Section>
        <Section>
          <p className="typography-body text-neutral-800">
            <span className="typography-body font-semibold text-neutral-800">
              Therapy Acknowledgement (if applicable):
            </span>
            I acknowledge that therapy outcomes may vary, and there are no guarantees of specific results. However, I am
            committed to supporting my growth and well-being throughout the therapeutic process. I understand/agree
            that:
          </p>
          <List items={THERAPY_ACKNOWLEDGEMENT} type="decimal" />
        </Section>
        <Section>
          <p className="typography-body text-neutral-800">
            <span className="typography-body font-semibold text-neutral-800">Revocation: </span> I realize that I may at
            any time refuse to consent to a continuation of the Telehealth Consultations or revoke this consent. In
            doing so, I may be requested to sign a form acknowledging this decision.
          </p>
        </Section>
        <Section>
          <p className="typography-body font-semibold text-neutral-800 underline">
            By selecting the check mark below, I authorize and give my informed consent to TimelyCare to provide me
            treatment, diagnosis, observations, and recommendations regarding my condition through telehealth services
            as described and agree to the{' '}
            <ExternalLink href={TERMS_CONDITIONS_LINK} className="text-primary-500 underline">
              Terms & Conditions
            </ExternalLink>{' '}
            . Whenever necessary, I authorize TimelyCare to consult with other physicians, healthcare providers,
            emergency personnel, or specialists whom they believe to have full knowledge and skills that can address my
            case. I have read and understood the information provided above, my rights and obligations regarding
            telehealth. I have had the opportunity to ask questions and received satisfactory responses.
          </p>
        </Section>
        <Section>
          <p className="typography-body text-neutral-800">
            I attest that there is no other individual who is required to consent to my care. If there is another
            individual who is required to consent to my care, I agree to provide their name and contact information so
            that they may be contacted, as appropriate.
          </p>
        </Section>
        <Section>
          <p className="typography-body text-neutral-800">
            I further acknowledge that by selecting the checkmark, it is held to the same standard as a handwritten or
            electronic signature on a paper document, and it indicates my full acceptance and agreement to the terms and
            conditions outlined in this consent form.
          </p>
        </Section>
      </div>
    </Modal>
  )
})

export default SelfConsentTermsModal
