import { useDispatch } from 'react-redux'
import { handleRequestFailure, makeRequest } from 'api/helpers'
import { setFetching } from 'redux/slices/api'
import FetchTypes from 'util/fetch-types'
import {
  ConsentStatuses,
  ConsentTypes,
  MinorConsentPhase2Type,
  SelfConsentApprovalType,
  TVisitType,
} from 'util/visit-types'

export const useMinorConsent = (memberId: string) => {
  const dispatch = useDispatch()

  const getMinorConsentByService = async (
    service: TVisitType,
    state: string,
  ): Promise<{ success: boolean; result: MinorConsentPhase2Type[] }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getMemberConsents, isFetching: true }))
    const params = { params: { filter: { memberId, service, state } } }
    let success = false
    let result = null

    const handleSuccess = (data: MinorConsentPhase2Type[]) => {
      success = true
      result = data
    }

    await makeRequest(FetchTypes.getMemberConsents, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchTypes.getMemberConsents, isFetching: false }))
    return { success, result }
  }

  const getConsentTypeAndStatus = async (
    service: TVisitType,
    state: string,
  ): Promise<{ isParentConsentGranted: boolean; isSelfConsentGranted: boolean; selfConsentPreApprovalId: string }> => {
    const { result } = await getMinorConsentByService(service, state)

    const parentConsentGranted = result?.filter(
      (consent) => consent?.status === ConsentStatuses.completed && consent?.type === ConsentTypes.parent,
    )[0]

    const selfConsentGranted = result?.filter(
      (consent) => consent?.status === ConsentStatuses.completed && consent?.type === ConsentTypes.self,
    )[0]

    const pendingSelfConsent = result?.filter(
      (consent) => consent?.status === ConsentStatuses.pending && consent?.type === ConsentTypes.self,
    )[0]

    const selfConsentPreApprovalId =
      !parentConsentGranted && !selfConsentGranted ? pendingSelfConsent?.selfConsentPreApprovalId : undefined

    return {
      isParentConsentGranted: !!parentConsentGranted,
      isSelfConsentGranted: !!selfConsentGranted,
      selfConsentPreApprovalId,
    }
  }

  const postSelfConsentConfirmation = async (
    preApprovalId: string,
  ): Promise<{ result: SelfConsentApprovalType; success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.postMemberSelfConsent, isFetching: true }))
    let success = false
    let result = null

    const params = {
      preApprovalId,
    }

    const handleSuccess = (data: SelfConsentApprovalType) => {
      result = data
      success = true
    }

    await makeRequest(FetchTypes.postMemberSelfConsent, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchTypes.postMemberSelfConsent, isFetching: false }))

    return { result, success }
  }

  return { getConsentTypeAndStatus, postSelfConsentConfirmation }
}
