import useMemberGroupType from 'hooks/useMemberGroupType'
import posthog from 'posthog-js'
import { usePostHog } from 'posthog-js/react'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getLoggedInMember, getMemberAge } from 'redux/selectors/members'
import {
  getClient,
  getGroup,
  getServiceByType,
  getServiceLimitResetMap,
  getServiceTypes,
} from 'redux/selectors/services'
import { getMemberLastPendingVisit } from 'redux/selectors/visits'
import { fetchClientVisitCount, fetchGroup } from 'redux/thunks/clients'

/**
 * Component that handles user identification and tracking across multiple analytics platforms.
 * Currently supports Pendo and PostHog, with architecture ready for additional platforms.
 */
const UserAnalytics = () => {
  const member = useSelector(getLoggedInMember)
  const age = useSelector(getMemberAge(member?.memberId))
  const client = useSelector(getClient(member?.clientId))
  const group = useSelector(getGroup(member?.groupId))
  const serviceTypes = useSelector(getServiceTypes)
  const lastPendingVisit = useSelector(getMemberLastPendingVisit(member?.memberId))
  const serviceLimitResetMap = useSelector(getServiceLimitResetMap)
  const scheduledTherapyService = useSelector(getServiceByType('therapy', 'scheduled'))
  const { memberGroupType } = useMemberGroupType()
  const dispatch = useDispatch()
  const location = useLocation()
  const posthog = usePostHog()

  const [scheduledTherapyVisitsRemaining, setScheduledTherapyVisitsRemaining] = useState<number | undefined>(undefined)
  const [isGroupFetched, setIsGroupFetched] = useState(false)
  const [isVisitCountFetched, setIsVisitCountFetched] = useState(false)
  const initializedRef = useRef(false)
  const accountIdRef = useRef<string | null>(null)
  const visitorIdRef = useRef<string | null>(null)
  const lastPosthogIdentifyRef = useRef<string | null>(null)

  const handleFetchGroup = useCallback(
    async (groupId: string) => {
      if (groupId) {
        await dispatch(fetchGroup(groupId))
        setIsGroupFetched(true)
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (member?.groupId) {
      handleFetchGroup(member.groupId)
    }
  }, [handleFetchGroup, member?.groupId])

  const fetchVisitCount = useCallback(async () => {
    // @ts-ignore
    const { count } = await dispatch(
      fetchClientVisitCount(member.clientId, { visitType: 'therapy', scheduledType: 'scheduled' }),
    )
    if (count && scheduledTherapyService) {
      setScheduledTherapyVisitsRemaining(scheduledTherapyService?.limitMember - count)
    }
    setIsVisitCountFetched(true)
  }, [dispatch, member?.clientId, scheduledTherapyService])

  useEffect(() => {
    if (member?.clientId) {
      fetchVisitCount()
    }
  }, [fetchVisitCount, member?.clientId])

  const attributes = useMemo(
    () => ({
      visitor: {
        id: member?.memberId,
        groupType: memberGroupType,
        email: member?.email,
        sex: member?.gender,
        age,
        origin: 'web',
        registeredDate: member?.registeredAt,
        gender: member?.genderIdentity || undefined,
        ethnicity: member?.ethnicity || undefined,
        lastVisitDate: lastPendingVisit.completedAt || undefined,
        lastLogin: new Date().toISOString(),
        graduationSemester: member?.graduationSemester || undefined,
        graduationYear: member?.graduationYear || undefined,
        ...serviceLimitResetMap,
        scheduledTherapyVisitsRemaining: scheduledTherapyVisitsRemaining,
        environment: process.env.REACT_APP_ENVIRONMENT,
      },
      account: {
        id: member?.groupId,
        clientName: client?.name || undefined,
        clientId: member?.clientId || undefined,
        groupName: group?.name,
        services: serviceTypes || undefined,
        environment: process.env.REACT_APP_ENVIRONMENT,
      },
    }),
    [
      age,
      client,
      group,
      lastPendingVisit.completedAt,
      member,
      scheduledTherapyVisitsRemaining,
      serviceLimitResetMap,
      serviceTypes,
      memberGroupType,
    ],
  )

  const minimalAttributesReady = !!attributes.visitor.id && !!attributes.account.id
  const allDataFetched = isGroupFetched && isVisitCountFetched

  // Handle Pendo identification
  useEffect(() => {
    if (window.pendo && minimalAttributesReady) {
      if (initializedRef.current) {
        if (accountIdRef.current !== attributes.account.id || visitorIdRef.current !== attributes.visitor.id) {
          window.pendo.identify(attributes)
        } else {
          window.pendo.updateOptions(attributes)
        }
      } else {
        window.pendo?.initialize(attributes)
        initializedRef.current = true
      }
      accountIdRef.current = attributes.account.id
      visitorIdRef.current = attributes.visitor.id
    }
  }, [attributes, minimalAttributesReady])

  // Handle PostHog identification
  const isPosthogAttributesReady =
    !!attributes.visitor.id && !!attributes.account.id && !!attributes.account.services && allDataFetched

  useEffect(() => {
    if (isPosthogAttributesReady) {
      const attributesKey = JSON.stringify(attributes)
      // Only identify if attributes have changed
      if (lastPosthogIdentifyRef.current !== attributesKey) {
        posthog.identify(attributes.visitor.id, {
          ...attributes.visitor,
        })

        if (attributes.account.id) {
          posthog.group('company', attributes.account.id, {
            ...attributes.account,
          })
        }

        lastPosthogIdentifyRef.current = attributesKey
      }
    }
  }, [attributes, isPosthogAttributesReady, posthog])

  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview')
    }
  }, [location, posthog])

  return null
}

export default UserAnalytics
